<template>
  <b-card
    id="new-real-estate-accommodation-card"
    title="Crea un nou allotjament"
  >
    <b-row>
      <!-- Reference -->
      <b-col cols="12" sm="6" lg="4" xl="2">
        <b-form-group label="Referència" label-for="reference">
          <b-form-input
            id="reference"
            v-model="accommodation.reference"
            placeholder="Referència"
          />
        </b-form-group>
      </b-col>
      <!-- Accommodation Type -->
      <b-col cols="12" sm="6" lg="4" xl="3">
        <b-form-group label="Tipus" label-for="accommodationType">
          <v-select
            id="accommodationType"
            v-model="accommodation.type"
            placeholder="Selecciona un tipus d'allotjament"
            :options="accommodationTypeOptions"
            :reduce="(option) => option.value"
          />
        </b-form-group>
      </b-col>
      <!-- Price -->
      <b-col cols="12" lg="4" xl="3">
        <b-form-group>
          <label for="basic-password">Preu</label>
          <b-input-group>
            <b-form-input
              id="price"
              v-model="userPrice"
              type="number"
              placeholder="Preu de venda"
            />
            <b-input-group-append is-text> € </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- Bedrooms -->
      <b-col v-if="accommodationTypeHasArrangement" cols="6" xl="2">
        <b-form-group label="Dormitoris" label-for="bedrooms">
          <b-form-spinbutton
            id="bedrooms"
            v-model="accommodation.bedrooms"
            min="1"
            max="20"
            step="1"
            placeholder="--"
          />
        </b-form-group>
      </b-col>
      <!-- Bathrooms -->
      <b-col v-if="accommodationTypeHasArrangement" cols="6" xl="2">
        <b-form-group label="Banys" label-for="bathrooms">
          <b-form-spinbutton
            id="bathrooms"
            v-model="accommodation.bathrooms"
            min="1"
            max="20"
            step="1"
            placeholder="--"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormGroup,
  BFormSpinbutton,
} from "bootstrap-vue";
import { realEstateAccommodationTypeOptions } from "@/utils/select-options";
import vSelect from "vue-select";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "InfoCard",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormSpinbutton,
    vSelect,
  },
  data() {
    return {
      userPrice: null,
      accommodation: {
        uuid: uuidv4(),
        status: "DRAFT",
        reference: null,
        type: null,
        price: null,
        bedrooms: null,
        bathrooms: null,
      },
    };
  },
  computed: {
    accommodationTypeOptions() {
      return realEstateAccommodationTypeOptions;
    },
    accommodationTypeHasArrangement() {
      switch (this.accommodation.type) {
        case "PLOT":
        case "BUILDABLE_RUSTIC_LAND":
        case "NON_BUILDABLE_RUSTIC_LAND":
          return false;
        default:
          return true;
      }
    },
  },
  watch: {
    userPrice(price) {
      if (price) this.accommodation.price = Math.round(price * 100);
      else this.accommodation.price = null;
    },
    accommodation: {
      deep: true,
      handler() {
        this.$emit("change", this.accommodation);
      },
    },
    accommodationTypeHasArrangement(hasArrangement) {
      if (!hasArrangement) {
        this.accommodation.bedrooms = null;
        this.accommodation.bathrooms = null;
      }
    },
  },
  created() {
    this.$emit("change", this.accommodation);
  },
  mounted() {
    this.getReferenceNumber();
  },
  methods: {
    resetInputs() {
      this.userPrice = null;
      this.getReferenceNumber();
      this.accommodation.type = null;
      this.accommodation.price = null;
      this.accommodation.bedrooms = 1;
      this.accommodation.bathrooms = 1;
    },
    getReferenceNumber() {
      this.$store
        .dispatch("realEstateAccommodations/getUnusedReferenceNumber")
        .then((reference) => {
          this.accommodation.reference = reference;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
