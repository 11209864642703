<template>
  <div id="new-real-estate-accommodation-page">
    <b-row>
      <b-col>
        <info-card @change="onAccommodationUpdated" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <location-card @change="onLocationUpdated" />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-2 d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="onCancelButtonClicked">
          Cancel·la
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="onSaveButtonClicked">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import InfoCard from "@/views/real-estate-accommodations/new-real-estate-accommodation/components/InfoCard.vue";
import LocationCard from "@/views/real-estate-accommodations/new-real-estate-accommodation/components/LocationCard.vue";

export default {
  name: "NewRealEstateAccommodation",
  components: {
    BRow,
    BCol,
    BButton,
    InfoCard,
    LocationCard,
  },
  data() {
    return {
      accommodation: null,
      location: null,
    };
  },
  methods: {
    onAccommodationUpdated(accommodation) {
      this.accommodation = accommodation;
    },
    onLocationUpdated(location) {
      this.location = location;
    },
    onCancelButtonClicked() {
      this.$router.push({ name: "foravila-real-estate-accommodations-list" });
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("realEstateAccommodation/createAccommodation", {
          uuid: this.accommodation.uuid,
          status: this.accommodation.status,
          reference: this.accommodation.reference,
          type: this.accommodation.type,
          // ! `price` parameter must be a string because in the database is stored as a `bigint`
          price: this.accommodation.price.toString(),
          bedrooms: this.accommodation.bedrooms,
          bathrooms: this.accommodation.bathrooms,
          location: this.location,
        })
        .then(() =>
          this.$router.push({
            name: "foravila-real-estate-accommodation-view",
            params: { accommodationUuid: this.accommodation.uuid },
          })
        )
        .catch(() =>
          notifyError(
            "Allotjament no creat",
            "L'allotjament no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
